<template>
    <div class="body">
        <!-- banner区域 Start -->
        <div class="banner">
            <m-top-bar></m-top-bar>
        </div>
        <!-- banner区域 End -->

        <!-- switchBar区域 Start -->
        <div class="switchBar">
            <div class="switchBox">工业覆盖传输产品系列</div>
        </div>
        <div class="line"></div>
        <!-- switchBar区域 End -->

        <!-- container区域 Start -->
        <div class="container">
            <div class="containerBox">
                <div class="productBox product" @click="toBaseStation">
                    <img src="../../../assets/mobile/img/product/wirelessFastSwitchingBaseStation.png" alt="" class="pic">
                    <img src="../../../assets/mobile/img/product/next.png" alt="" class="next">
                    <span class="productTitle">无线快速切换基站</span>
                </div>
                <div class="productBox product" @click="toExplosionBaseStation">
                    <img src="../../../assets/mobile/img/product/explosionProofAP.png" alt="" class="pic">
                    <img src="../../../assets/mobile/img/product/next.png" alt="" class="next">
                    <span class="productTitle">防爆型无线快速切换基站</span>
                </div>
                <div class="productBox product" @click="toWirelessPoint">
                    <img src="../../../assets/mobile/img/product/wirelessBaseStation.png" alt="" class="pic">
                    <img src="../../../assets/mobile/img/product/next.png" alt="" class="next">
                    <span class="productTitle">吸顶式室内无线接入点</span>
                </div>
                <div class="productBox" @click="toNetworkBridge">
                    <img src="../../../assets/mobile/img/product/wirelessTransmissionBridge.png" alt="" class="pic">
                    <img src="../../../assets/mobile/img/product/next.png" alt="" class="next">
                    <span class="productTitle">高带宽无线传输网桥</span>
                </div>
            </div>
        </div>
        <!-- container区域 End -->

        <!-- baseBar区域 Start -->
        <m-base-bar></m-base-bar>
        <!-- baseBar区域 End -->
    </div>
</template>

<script>
import mTopBar from '@/components/mTopBar.vue'
import mBaseBar from '@/components/mBaseBar.vue'

export default {
    name: 'Support',
    components: {
        mTopBar,
        mBaseBar,
    },
    methods: {
        toBaseStation() {
            this.$router.push('mProductIndustryBaseStation')
        },
        toExplosionBaseStation() {
            this.$router.push('mProductIndustryExplosionBaseStation')
        },
        toWirelessPoint() {
            this.$router.push('mProductIndustryWirelessPoint')
        },
        toNetworkBridge() {
            this.$router.push('mProductIndustryNetworkBridge')
        }
    }
}
</script>

<style scoped>
.body {
    background-color: #f7f7f7 !important;
}

/* banner区域 */
.banner {
    width: 100%;
    min-height: 2050px;
    background: url(../../../assets/mobile/img/product/banner.jpg) center no-repeat;
    background-size: 335%;
}

/* switchBar区域 */
.switchBar {
    padding-top: 50px;
    width: 100%;
    height: 300px;
    text-align: center;
    background-color: #ffffff;
}

.switchBar .switchBox {
    margin: 0 auto;
    text-align: center;
    width: 1000px;
    height: 200px;
    font-size: 82px;
    line-height: 200px;
    color: #ffffff;
    border-radius: 30px;
    background-color: #c3a35d;
}

.line {
    margin: 0 auto;
    width: 90%;
    border-bottom: 1px solid #c3a35d;
}

/* container区域 */
.container {
    padding: 50px;
    width: 100%;
    min-height: 500px;
}

.container .containerBox {
    padding: 100px;
    width: 100%;
    min-height: 300px;
    font-size: 70px;
    background-color: #ffffff;
}

.container .containerBox .product {
    margin-bottom: 100px;
}

.container .containerBox .productBox {
    position: relative;
    width: 100%;
    height: 800px;
    background-size: 110%;
    box-shadow: 10px 20px 50px 1px rgb(32 32 32 / 30%);
}

.container .containerBox .productBox .productTitle {
    position: absolute;
    left: 100px;
    bottom: 50px;
    font-size: 70px;
}

.container .containerBox .productBox .pic {
    position: absolute;
    top: 150px;
    left: 200px;
    height: 400px;
}

.container .containerBox .productBox .next {
    position: absolute;
    bottom: 300px;
    right: 100px;
    width: 200px;
}
</style>